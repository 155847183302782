<template>
  <div class="wrapper-content">
    <div class="steps">
      <div class="steps__content">
        <h2 class="steps__title">Как получить</h2>
        <p class="steps__desc">
          Вы заполняете анкету — мы ищем для вас<br /><strong
            >лучшие предложения</strong
          >
        </p>
        <ul class="steps__list steps-list">
          <li
            v-for="({ title, desc }, index) in steps"
            :key="index"
            class="steps-list__item"
          >
            <div class="steps-list__top">
              <p class="steps-list__num" ref="step">
                {{ index + 1 }}
              </p>
              <h4 class="steps-list__title">
                {{ title }}
              </h4>
            </div>
            <p class="steps-list__desc">
              {{ desc }}
            </p>
          </li>
        </ul>
        <div class="steps__video">Видеоинструкция</div>
        <ul class="steps__links">
          <li v-for="({ link, title }, index) in links" :key="index">
            <span class="steps__link" @click="scrollTo(link)">
              {{ title }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <!--<video
      ref="video"
      autoplay
      loop
    >
      <source src="./assets/video.mp4" type="video/mp4" />
    </video>-->
  </div>
</template>

<script>
import scrollTo from "@/helpers/scrollTo";

export default {
  name: "Steps",
  data() {
    return {
      links: [
        {
          title: "Условия получения займа",
          link: "conditions",
        },
        {
          title: "Частые вопросы",
          link: "faq",
        },
      ],
      steps: [
        {
          title: "Заполните анкету",
          desc: "Вам понадобится только паспорт и телефон",
        },
        {
          title: "Получите подтверждение",
          desc: "Максимально выгодные предложения по займу",
        },
        {
          title: "Заберите деньги",
          desc: "Вывод денег удобным для вас способом",
        },
      ],
    };
  },
  mounted() {
    // this.initVideo();
  },
  methods: {
    scrollTo(anchor) {
      scrollTo(`#${anchor}`);
    },
    // initVideo() {
    //   const video = this.$refs.video;
    //
    //   if (!video)
    //     return
    //
    //   video.addEventListener('timeupdate', function() {
    //     console.log(this.currentTime);
    //   })
    //
    //   this.$refs.step.forEach((step, index) => {
    //     step.addEventListener('mouseenter', () => {
    //       video.currentTime = index;
    //     });
    //   })
    // }
  },
};
</script>
